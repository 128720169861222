var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loading)?_c('div',{staticClass:"mx-1"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"mr-2 hidden_when_small",staticStyle:{"width":"100px","float":"right"}},[_c('v-select',{staticClass:"assign_select mt-2",attrs:{"items":[10, 20, 50, 100],"outlined":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})],1)])],1),_vm._l((_vm.sorted_cameras),function(camera,index){return _c('v-row',{key:camera.id},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"xl":"3","lg":"3","cols":"12"}},[_c('span',{staticClass:"thumbnail_span"},[_c('span',{staticClass:"cam_model_grid"},[_c('span',{staticClass:"darker_bckrnd"},[_vm._v(_vm._s(camera.name))])]),_c('vue-load-image',{staticStyle:{"width":"100%","height":"calc(100% - 10px)"}},[_c('img',{staticClass:"grid_img_f",attrs:{"slot":"image","alt":"","src":`${
                _vm.cookie_bucket +
                _vm.customer_id +
                '/thumbnails/' +
                camera.id +
                '_latest.jpg'
              }`},slot:"image"}),_c('img',{staticClass:"grid_img_f",attrs:{"slot":"preloader","src":require("@/assets/loader.png")},slot:"preloader"}),_c('img',{staticClass:"grid_img_f",attrs:{"slot":"error","src":require("@/assets/no_media_light.png")},slot:"error"})])],1)]),_c('v-col',{staticClass:"py-0 bar_col",attrs:{"xl":"3","lg":"3","cols":"12"}},[_c('chart_summary_media',{attrs:{"labels":_vm.labels,"datasets":_vm.by_camera_data[index].media_dataset,"timeframe":_vm.timeframe,"title":"Inferred Media"}})],1),_c('v-col',{staticClass:"py-0 bar_col",attrs:{"xl":"3","lg":"3","cols":"12"}},[_c('chart_doughnut_summary_media',{attrs:{"labels":['Videos', 'Images'],"colors":['#FBC02D', '#388E3C'],"datasets":_vm.by_camera_data[index].media_pie,"timeframe":_vm.timeframe,"title":"Inferred Media by Type"}})],1),_c('v-col',{staticClass:"py-0 bar_col",attrs:{"xl":"3","lg":"3","cols":"12"}},[_c('chart_summary_media',{attrs:{"labels":_vm.labels,"datasets":_vm.by_camera_data[index].objects_per_media,"timeframe":_vm.timeframe,"title":"Detected Animals by Image"}})],1)],1)})],2):_c('v-row',{attrs:{"justify":"space-around"}},[_c('div',{staticStyle:{"text-align":"center","margin-top":"100px","height":"79vh"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"300"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }