<template>
  <div>
    <div v-if="!loading" class="mx-1">
      <v-row><v-col cols="12" class="py-0">
        <span class="mr-2 hidden_when_small" style="width: 100px; float: right"
          ><v-select
            v-model="limit"
            :items="[10, 20, 50, 100]"
            outlined
            rounded
            dense
            hide-details
            class="assign_select mt-2"
        /></span> </v-col
      ></v-row>
      <v-row
        v-for="(camera, index) in sorted_cameras"
        :key="camera.id"
        class=""
      >
        <v-col xl="3" lg="3" cols="12" class="pa-0 ma-0">
          <span class="thumbnail_span">
            <span class="cam_model_grid"
              ><span class="darker_bckrnd">{{ camera.name }}</span>
            </span>

            <vue-load-image style="width: 100%; height: calc(100% - 10px)">
              <img
                slot="image"
                alt=""
                :src="`${
                  cookie_bucket +
                  customer_id +
                  '/thumbnails/' +
                  camera.id +
                  '_latest.jpg'
                }`"
                class="grid_img_f"
              />
              <img
                slot="preloader"
                class="grid_img_f"
                src="@/assets/loader.png"
              />
              <img
                slot="error"
                class="grid_img_f"
                src="@/assets/no_media_light.png"
              />
            </vue-load-image>
          </span>
        </v-col>
        <v-col xl="3" lg="3" cols="12" class="py-0 bar_col">
          <chart_summary_media
            :labels="labels"
            :datasets="by_camera_data[index].media_dataset"
            :timeframe="timeframe"
            title="Inferred Media"
          />
        </v-col>
        <v-col xl="3" lg="3" cols="12" class="py-0 bar_col">
          <chart_doughnut_summary_media
            :labels="['Videos', 'Images']"
            :colors="['#FBC02D', '#388E3C']"
            :datasets="by_camera_data[index].media_pie"
            :timeframe="timeframe"
            title="Inferred Media by Type"
          />
        </v-col>
        <v-col xl="3" lg="3" cols="12" class="py-0 bar_col">
          <chart_summary_media
            :labels="labels"
            :datasets="by_camera_data[index].objects_per_media"
            :timeframe="timeframe"
            title="Detected Animals by Image"
          />
        </v-col>
      </v-row>
    </div>
    <v-row justify="space-around" v-else>
      <div style="text-align: center; margin-top: 100px; height: 79vh">
        <v-progress-circular
          indeterminate
          color="primary"
          size="300"
        ></v-progress-circular>
      </div>
    </v-row>
  </div>
</template>
  
<script>
import VueLoadImage from "vue-load-image";
import moment from "moment";
import chart_summary_media from "@/components/summary_media/chart_summary_media.vue";
//import chart_pi_summary_media from "@/components/summary_media/chart_pi_summary_media.vue";
import chart_doughnut_summary_media from "@/components/summary_media/chart_doughnut_summary_media.vue";

export default {
  name: "camera",
  props: ["cameras"],

  components: {
    "vue-load-image": VueLoadImage,
    chart_summary_media,
    //chart_pi_summary_media,
    chart_doughnut_summary_media,
  },
  data() {
    return {
      limit: 10,
      timeframe: "1day",
      loading: true,
    };
  },
  methods: {},
  watch: {
    limit() {
      this.loading = true;
      this.$store
        .dispatch("DDB_GET_BY_CAMERA_ANALYTICS", {
          timeframe: this.timeframe,
          limit: this.limit,
          cameras: this.sorted_cameras,
        })
        .then(() => {
          this.loading = false;
        });
    },
    sorted_cameras() {
      this.$store
        .dispatch("DDB_GET_BY_CAMERA_ANALYTICS", {
          timeframe: this.timeframe,
          limit: this.limit,
          cameras: this.sorted_cameras,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    labels() {
      let labels = [];
      let i = 0;
      while (i < this.limit) {
        labels.unshift(moment().subtract(i, "days").format("MM-DD"));
        i++;
      }
      return labels;
    },
    chart_time_format() {
      let time_format = "MM-DD HH:mm";
      if (this.timeframe_tab == 0) {
        time_format = "MM-DD";
      }
      return time_format;
    },
    sorted_cameras() {
      let cameras = [...this.cameras];
      return cameras.sort((a, b) => {
        return (
          moment(b.latest_upload, "YYYY-MM-DDTHH:mm:ss") >
          moment(a.latest_upload, "YYYY-MM-DDTHH:mm:ss")
        );
      });
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },

    by_camera_data() {
      return this.$store.getters.getByCameraData;
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
  },
  mounted() {
    if (this.sorted_cameras.length > 0) {
      this.$store
        .dispatch("DDB_GET_BY_CAMERA_ANALYTICS", {
          timeframe: this.timeframe,
          limit: this.limit,
          cameras: this.sorted_cameras,
        })
        .then(() => {
          this.loading = false;
        });
    }
  },

  created() {},
};
</script>
<style lang="css" scoped>
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bar_col > div {
  height: 304px;
  position: relative;
  min-height: 272px;
}
.cam_model_grid {
  position: relative;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 22px;
  width: 95%;
  top: 28px;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 4px;
  padding-right: 4px;
}
.thumbnail_span {
  display: block;
  height: 100%;
  width: 100%;
  margin-top: -24px;
}
</style>
  