export function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    if (x == null) {
      x = "zzz";
    }
    if (y == null) {
      y = "zzz";
    }
    if (key == "camera_latest_upload_source") {
      if (x == y) {
        return a.camera_name.localeCompare(b.camera_name);
      }
    }

    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function sortByKeyDESC(array, key) {
  return array.sort(function (a, b) {
    var x = b[key];
    var y = a[key];
    if (x == null) {
      x = "zzz";
    }
    if (y == null) {
      y = "zzz";
    }
    if (key == "camera_latest_upload_source") {
      if (x == y) {
        return a.camera_name.localeCompare(b.camera_name);
      }
    }
    return x < y ? -1 : x > y ? 1 : 0;
  });
}


export default {
  sortByKey,
  sortByKeyDESC,
}