
<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer" :sub_path="sub_path"></sidenav>
    <v-container fluid class="py-0"
      ><v-row align-content="center" justify="center" class="mx-0">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="3">
            <v-card-title class="pl-2 pt-3">
              <div class="oc_table_header">
                <v-tabs class="table_tabs" v-model="selected_table">
                  <v-tab>Overview</v-tab>
                  <v-tab>Status</v-tab>
                  <v-tab>Recent Footage</v-tab>
                  <v-tab>By Camera</v-tab>
                  <!-- <v-tab>Notes</v-tab> -->
                </v-tabs>
              </div>
              <v-spacer class="mb_hidden"></v-spacer>

              <v-switch
                class="pt-0 mr-6 ml-1 edit_switch"
                style="margin-top: 6px; margin-bottom: 6px"
                v-model="editable"
                label="Edit"
                color="primary"
                value="Edit"
                inset
                v-if="allowed_operations == 'WRITE' && selected_table == 1"
                hide-details
              ></v-switch>
              <v-tooltip v-else-if="selected_table == 1" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-switch
                      class="mt-0 pt-0 mr-6"
                      v-model="editable"
                      label="Edit"
                      color="primary"
                      value="Edit"
                      inset
                      disabled
                      hide-details
                    ></v-switch>
                  </div>
                </template>
                <span>You don't have permission to edit this data</span>
              </v-tooltip>

              <div
                class="table_search_field mb_max_width"
                v-if="selected_table == 1"
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="pt-0 mt-0 assign_select assign_select_v font_400 rounded-lg"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  height="32"
                  full-width
                  outlined
                ></v-text-field>
              </div>
            </v-card-title>

            <v-card-text class="text-center">
              <v-data-table
                v-if="selected_table == 1"
                :headers="headers_general"
                :items="camera_objects"
                :items-per-page="100"
                :search="search"
                class="elevation-1 livestock_table"
                hide-default-footer
                item-key="id"
                :options.sync="options"
                no-data-text="
          No data available
                "
                data-cy="card_cameras"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <div class="oc_table_header">
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </div>
                </template>
                <template v-slot:[`item.icon`]="{ item }">
                  <v-icon
                    class="cam_icon"
                    v-if="item.model == 'RLC-511W' || item.model == 'RLC-511WA'"
                    >$rlc_511</v-icon
                  >
                  <v-icon
                    class="cam_icon"
                    v-else-if="
                      item.model == 'E1 Zoom' || item.model == 'E1 Outdoor'
                    "
                    >$zoom_e1</v-icon
                  ><v-icon
                    class="cam_icon"
                    v-else-if="
                      item.model == 'Argus PT' || item.model == 'Argus PT 6'
                    "
                    >$argus_go</v-icon
                  >
                  <v-icon
                    class="cam_icon"
                    v-else-if="item.model == 'Reolink Go PT'"
                    >$reolink_go</v-icon
                  >
                  <v-icon
                    class="cam_icon"
                    v-else-if="item.model == 'ReoLink Cloud'"
                    >$r511w</v-icon
                  >
                </template>
                <template v-slot:[`item.name`]="{ item, index }">
                  <v-edit-dialog
                    large
                    @save="save_record(item.id, 'name', index)"
                    @cancel="cancel_edit"
                    @open="open_field(item.name)"
                  >
                    {{ item.name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Camera Name"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <span v-if="item.status == 'ONLINE'" class="green_color">{{
                    item.status
                  }}</span>
                  <span v-if="item.status == 'OFFLINE'" class="gray_color">{{
                    item.status
                  }}</span>
                  <span v-if="item.status == 'WARNING'" class="orange_color">{{
                    item.status
                  }}</span>
                </template>
                <template v-slot:[`item.latest_upload`]="{ item }">
                  <span v-if="item.latest_upload"
                    >{{ item.latest_upload.split("T")[0] }} -
                    {{ lu_age(item.latest_upload.split("T")[0]) }}</span
                  >
                </template>
                <template v-slot:[`item.latest_upload_age`]="{ item }">
                  <span v-if="item.latest_upload">{{
                    lu_age(item.latest_upload.split("T")[0])
                  }}</span>
                </template>
                <template v-slot:[`item.site_name`]="{ item }">
                  <span v-if="item.site">
                    <span v-if="item.site.site_name.includes(' - ')">{{
                      item.site.site_name.split([" - "])[1]
                    }}</span>
                    <span v-else>{{ item.site.site_name }}</span></span
                  >
                </template>

                <template v-slot:[`item.last_status_update`]="{ item }">
                  <span v-if="item.last_status_update != null">{{
                    item.last_status_update.split(" ")[0]
                  }}</span>
                  <span v-else>{{ item.last_status_update }}</span>
                </template>

                <template v-slot:[`item.last_video_upload`]="{ item }">
                  <span v-if="item.last_video_upload != null">{{
                    item.last_video_upload.split(" ")[0] == "1900-01-01"
                      ? "never online"
                      : item.last_video_upload.split(" ")[0]
                  }}</span>
                  <span v-else>{{ item.last_video_upload }}</span>
                </template>

                <template v-slot:[`item.blurriness_label`]="{ item }">
                  <span v-if="item.blurriness_label">{{
                    item.blurriness_label.replace("_", " ")
                  }}</span>
                  <span v-else>-</span>
                </template>
              </v-data-table>
              <footage-uploads v-if="selected_table == 2" />
              <camera v-if="selected_table == 0" :cameras="camera_objects" />
              <by_camera v-if="selected_table == 3" :cameras="camera_objects" />
            </v-card-text>
          </v-card>
        </v-col> </v-row
    ></v-container>

    <!-- <events></events> -->
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

import { eventBus } from "../main.js";

import moment from "moment";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
import FootageUploads from "@/components/FootageUploads.vue";
import camera from "@/components/dashboards/camera.vue";
import by_camera from "@/components/dashboards/by_camera.vue";

export default {
  components: {
    navbar,
    sidenav,
    FootageUploads,
    camera,
    by_camera
    //events,
  },
  props: ["filter"],
  data() {
    return {
      camera_objects: [],

      search: "",
      staging_cameras: [],
      production_cameras: [],
      staging_production: true,
      loading: true,
      selected_type: "All",
      ranches: ["All"],
      selected_site: "All",
      selected_customer: "All",
      yn_list: ["All", "YES", "NO"],
      status_list: ["All", "ONLINE", "WARNING", "OFFLINE"],
      cellular: "All",
      ftp: "All",
      reolink: "All",
      last_update_date: [],
      updated_at: [],
      last_upload_date: [],
      dialog: false,
      delete_dialog: false,
      create_new: false,
      selected_table: 0,
      owner_name: "",
      status: "All",
      site_name: "",
      editable: false,
      edit_value: null,

      options: { sortBy: ["status"], sortDesc: [true] },
    };
  },
  methods: {
    async save_record(id, field, index) {
      try {
        var input = {};
        input[field] = this.edit_value;

        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_camera_by_pk,
            variables: {
              pk_columns: {
                id: id,
              },
              _set: input,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.camera_objects[index][field] = this.edit_value;
        this.edit_value = null;
        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated the camera`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating the camera`,
          err: true,
        });
      }
    },
    cancel_edit() {
      this.edit_value = null;
    },

    open_field(value) {
      this.edit_value = value;
    },
    lu_age(date) {
      return moment().diff(moment(date, "YYYY-MM-DD"), "days") + " days";
    },
    add_dash(input) {
      if (
        input == "" ||
        input == null ||
        input == "null" ||
        input == "0" ||
        input == "N/A"
      ) {
        return "-";
      } else {
        return input;
      }
    },
    clear_last_update_date() {
      this.last_update_date = [];
    },
    clear_updated_at() {
      this.updated_at = [];
    },
    clear_last_upload_date() {
      this.last_upload_date = [];
    },

    close() {
      this.dialog = false;
      this.error = "";
    },
    deleteItemConfirm() {
      this.delete_dialog = false;
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },

    owner_filter() {
      if (this.filter != "all") {
        this.selected_customer =
          this.$store.getters.getOwnerNameObject[this.filter];
      }
    },
  },

  computed: {
    sub_path() {
      if (this.selected_table == 0) {
        return "overview";
      } else if (this.selected_table == 1) {
        return "status";
      } else if (this.selected_table == 2) {
        return "recent-footage";
      } else {
        return "by-camera";
      }
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
    // selected_headers() {
    //   if (this.selected_table == 0) {
    //     return this.headers_general;
    //   } else {
    //     return this.headers_status;
    //   }
    // },

    headers_general() {
      return [
        { text: "", value: "icon", width: "60px" },
        {
          text: "Camera Name",
          value: "name",
          width: "240px",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "Camera ID", value: "id", width: "240px" },
        {
          text: "Status",
          value: "status",
        },
        { text: "Bluriness", value: "blurriness_label", sortable: false },
        {
          text: "Model",
          value: "model",
        },
        { text: "Last Upload", value: "latest_upload" },
        // { text: "Bitrate (kbps)", value: "latest_upload_bitrate_kbps" },
        // { text: "FPS", value: "latest_upload_fps" },
        { text: "Resolution", value: "latest_upload_resolution" },
      ];
    },
    headers_status() {
      return [
        { text: "", value: "icon", width: "60px" },
        {
          text: "Camera Name",
          value: "name",
          width: "240px",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "Camera ID", value: "id", width: "240px" },

        { text: "Bitrate (kbps)", value: "latest_upload_bitrate_kbps" },
        { text: "FPS", value: "latest_upload_fps" },
        { text: "Resolution", value: "latest_upload_resolution" },
      ];
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
  },

  created() {
    eventBus.$on("change_tab", (tab) => {
      if (tab == "overview") {
        this.selected_table = 0;
      } else if (tab == "status") {
        this.selected_table = 1;
      } else if (tab == "recent-footage") {
        this.selected_table = 2;
      } else if (tab == "by-camera") {
        this.selected_table = 3;
      }
    });

    if (this.tab == "overview") {
        this.selected_table = 0;
      } else if (this.tab == "status") {
        this.selected_table = 1;
      } else if (this.tab == "recent-footage") {
        this.selected_table = 2;
      } else if (this.tab == "by-camera") {
        this.selected_table = 3;
      }
    if (this.$store.getters.getCameraObjects.length) {
      this.camera_objects = this.$store.getters.getCameraObjects;
    }
  },
  watch: {
    customer_id() {
      this.$store.dispatch("DDB_GET_CAMERAS").then(() => {
        this.camera_objects = this.$store.getters.getCameraObjects;
      });
    },
    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>


<style lang="css" scoped>
.cam_icon > svg {
  height: 30px !important;
  width: 30px !important;
  margin-top: 4px;
  margin-bottom: 4px;
}

.select_width > .v-input {
  max-width: 160px !important;
}

.align_left {
  text-align: left;
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}
.filter_btn {
  color: gray !important;
}
.v-select__selections {
  flex-wrap: nowrap !important;
}
</style>

