<script>
import { Doughnut } from "vue-chartjs";
//import { eventBus } from "../main.js";

// @vuese
// @group Components
export default {
  extends: Doughnut,
  props: ["labels", "datasets", "timeframe", "title", "legend_position", "colors"],
  data() {
    return {
      options_line: {
        title: {
          display: true,
          text: this.title,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        legend: {
          display: true,
          position: this.legend_position ? this.legend_position : "right",
        },
      },
    };
  },
  methods: {},
  computed: {
    data() {
      return {
        data_bar: {
          datasets: [
            {
              data: this.datasets,
              backgroundColor:  this.colors ? this.colors : [
                "#FBC02D",
                "#42A5F5",
                "#607D8B",
                "#093D72",
                "#ae4773",
                "#F06292",
                "#8D6E63",
                "#EF5350",
                "#BDBDBD"
              ],
              borderColor: "#fff",
            },
          ],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: this.labels,
        },
      };
    },
  },
  mounted() {
    if (this.datasets.length > 1) {
      this.renderChart(this.data.data_bar, this.options_line);
    }
  },
  watch: {
    datasets() {
      if (this.datasets.length > 1) {
        this.renderChart(this.data.data_bar, this.options_line);
      }
    },
  },
};
</script>