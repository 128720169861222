<template>
  <v-row justify="space-around" class="">
    <v-col xl="8" lg="8" cols="12" class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="dashboard_top">
          <v-card elevation="2">
            <v-card-text class="pa-0">
              <div id="grid-container-lf">
                <div
                  class="pa-0 grid_container_f"
                  v-for="(c, index) in sorted_cameras"
                  :key="index"
                  @click="select_camera(index)"
                >
                  <span style="display: flex">
                    <vue-load-image style="width: 100%">
                      <img
                        slot="image"
                        alt=""
                        :src="`${
                          cookie_bucket +
                          $store.getters.getCustomerID +
                          '/thumbnails/' +
                          c.id +
                          '_latest.jpg'
                        }`"
                        class="grid_img_f"
                      />
                      <img
                        slot="preloader"
                        class="grid_img_f"
                        src="@/assets/loader.png"
                      />
                      <img
                        slot="error"
                        class="grid_img_f"
                        src="@/assets/no_media_light.png"
                      />
                    </vue-load-image>
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="dashboard_bot">
          <v-card elevation="2">
            <v-card-text class="pa-0">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-tabs
                    v-model="timeframe_tab"
                    class="table_tabs_summary_dashboard"
                    style="max-width: 300px"
                  >
                    <v-tab>1 Day</v-tab>
                    <v-tab>1 Hour</v-tab>
                    <v-tab>5 Minutes</v-tab>
                  </v-tabs>
                  <span
                    class="mr-2 hidden_when_small"
                    style="width: 100px; float: right"
                    ><v-select
                      v-model="limit"
                      :items="[10, 20, 50, 100]"
                      outlined
                      rounded
                      dense
                      class="assign_select mt-2"
                  /></span>
                </v-col>
                <v-row class="ma-0 py-0 px-4" v-if="media_summary_data.length">
                  <v-col xl="4" lg="4" cols="12" class="bar_col pt-0">
                    <chart_summary_media
                      :labels="labels"
                      :datasets="inferred_media"
                      :timeframe="timeframe"
                      title="Inferred Media"
                    />
                  </v-col>
                  <v-col xl="4" lg="4" cols="12" class="bar_col pt-0">
                    <chart_doughnut_summary_media
                      :labels="['Videos', 'Images']"
                      :colors="['#FBC02D', '#388E3C']"
                      :datasets="inferred_media_pie"
                      :timeframe="timeframe"
                      title="Inferred Media by Type"
                    />
                  </v-col>
                  <v-col xl="4" lg="4" cols="12" class="bar_col pt-0">
                    <chart_summary_media
                      :labels="labels"
                      :datasets="detected_objects_per_media"
                      :timeframe="timeframe"
                      title="Detected Animals by Image"
                    />
                  </v-col>
                </v-row>

                <v-col v-else> No Media Inferred for this customer </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col xl="4" lg="4" cols="12" class="dashboard_left">
      <v-card height="100%">
        <v-card-text
          id="card_body"
          class="d-flex flex-column justify-space-between pa-3"
        >
          <div class="row_body d-flex align-center flex-column ma-0 mt-4">
            <span class="main_header">Cameras Online</span>
            <v-row style="width: 100%">
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3">
                  <span class="num">{{ camera_data.online_1d }}</span>
                  <span class="heading mt-2">Today</span>
                </div>
              </v-col>
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3 bordered">
                  <span class="num">{{ camera_data.online_7d }}</span>
                  <span class="heading mt-2">Weekly</span>
                </div>
              </v-col>
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3">
                  <span class="num">{{ camera_data.online_30d }}</span>
                  <span class="heading mt-2">Monthly</span>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="row_body d-flex align-center flex-column ma-0 mt-4">
            <span class="main_header">Camera Blurriness</span>
            <v-row style="width: 100%">
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3">
                  <span class="num">{{ blurriness_data.not_blurry }}</span>
                  <span class="heading mt-2">Clear</span>
                </div>
              </v-col>
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3 bordered">
                  <span class="num">{{ blurriness_data.blurry }}</span>
                  <span class="heading mt-2">Blurry</span>
                </div>
              </v-col>
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3">
                  <span class="num">{{ blurriness_data.undefined }}</span>
                  <span class="heading mt-2">Unknown</span>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="row_body d-flex align-center flex-column ma-0 mt-4">
            <span class="main_header">Upload Settings</span>
            <v-row style="width: 100%">
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3">
                  <span class="num">{{
                    camera_data.uploads_by_type.only_image_cameras
                  }}</span>
                  <span class="heading mt-2">Images</span>
                </div>
              </v-col>
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3 bordered">
                  <span class="num">{{
                    camera_data.uploads_by_type.only_video_cameras
                  }}</span>
                  <span class="heading mt-2">Videos</span>
                </div>
              </v-col>
              <v-col cols="4" class="pa-0">
                <div class="d-flex align-center flex-column pt-3">
                  <span class="num">{{
                    camera_data.uploads_by_type.both_image_and_video_cameras
                  }}</span>
                  <span class="heading mt-2">Both</span>
                </div>
              </v-col>
            </v-row>
          </div>

          <div class="mt-10">
            <v-row class="ma-0 pa-0" v-if="media_summary_data.length">
              <v-col cols="6" class="chart_smaller pt-0">
                <chart_doughnut_summary_media
                  :labels="resolution_pie.labels"
                  :datasets="resolution_pie.data"
                  :timeframe="timeframe"
                  :legend_position="'bottom'"
                  title="Resolution"
                />
              </v-col>
              <v-col cols="6" class="chart_smaller pt-0">
                <chart_pi_summary_media
                  :labels="blur_pie.labels"
                  :datasets="blur_pie.data"
                  :timeframe="timeframe"
                  :legend_position="'bottom'"
                  title="Blurriness"
                />
              </v-col>
              <v-col cols="6" class="chart_smaller pt-0">
                <chart_pi_summary_media
                  :labels="camera_models_pie.labels"
                  :datasets="camera_models_pie.data"
                  :timeframe="timeframe"
                  :legend_position="'bottom'"
                  title="Models"
                />
              </v-col>
              <v-col cols="6" class="chart_smaller pt-0">
                <chart_doughnut_summary_media
                  :labels="['images', 'videos', 'both']"
                  :datasets="[
                    camera_data.uploads_by_type.only_image_cameras,
                    camera_data.uploads_by_type.only_video_cameras,
                    camera_data.uploads_by_type.both_image_and_video_cameras,
                  ]"
                  :legend_position="'bottom'"
                  title="Upload by Type"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
  
<script>
import VueLoadImage from "vue-load-image";
import moment from "moment";
import chart_summary_media from "@/components/summary_media/chart_summary_media.vue";
import chart_pi_summary_media from "@/components/summary_media/chart_pi_summary_media.vue";
import chart_doughnut_summary_media from "@/components/summary_media/chart_doughnut_summary_media.vue";

export default {
  name: "camera",
  props: ["cameras"],

  components: {
    "vue-load-image": VueLoadImage,
    chart_summary_media,
    chart_pi_summary_media,
    chart_doughnut_summary_media,
  },
  data() {
    return {
      timeframe_tab: 0,
      limit: 10,
      labels: [],
      total_detected_objects: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Detected Objects",
        },
      ],
      inferred_media: [
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: [],
          backgroundColor: "#388E3C2",
          borderColor: "#fff",
          label: "Images",
        },
      ],
      inferred_media_pie: [0, 0],
    };
  },
  methods: {
    fetch_analytics() {
      this.$store.dispatch("DDB_GET_SUMMARY_MEDIA", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.$store.getters.getCustomerID,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_PGIE", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.$store.getters.getCustomerID,
      });

    },
  },
  watch: {
    timeframe() {
      this.fetch_analytics();
    },
    limit() {
      this.fetch_analytics();
    },
    customer_id() {
      this.fetch_analytics();
      this.$store.dispatch("DDB_GET_CAMERA_DASHBOARD_ANALYTICS");
    },
    media_summary_data() {
      var labels = [];

      var inferred_media = [[], []];
      var inferred_media_pie = [0, 0];

      var already_added = false;

      for (const i in this.media_summary_data) {
        if (
          !labels.includes(
            moment(this.media_summary_data[i].media_timestamp).format(
              this.chart_time_format
            )
          )
        ) {
          labels.unshift(
            moment(this.media_summary_data[i].media_timestamp).format(
              this.chart_time_format
            )
          );
        } else {
          already_added = true;
        }

        let index = this.limit - labels.length;

        if (index < 0) {
          labels.pop();
          break;
        }
        if (this.media_summary_data[i].media_type == "VIDEO") {
          inferred_media[0][index] =
            this.media_summary_data[i].num_frames_inferred;

          if (!already_added) {
            inferred_media[1][index] = 0;
          }

          inferred_media_pie[0] +=
            this.media_summary_data[i].num_frames_inferred;
        }
        if (this.media_summary_data[i].media_type == "IMAGE") {
          inferred_media[1][index] =
            this.media_summary_data[i].num_frames_inferred;

          if (!already_added) {
            inferred_media[0][index] = 0;
          }

          inferred_media_pie[1] +=
            this.media_summary_data[i].num_frames_inferred;
        }
      }

      this.labels = labels;

      this.inferred_media = [
        {
          data: inferred_media[0],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: inferred_media[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Images",
        },
      ];

      this.inferred_media_pie = inferred_media_pie;
    },
    pgie_summary_data() {
      var total_detected_objects = [];

      for (const i in this.pgie_summary_data) {
        total_detected_objects.unshift(
          this.pgie_summary_data[i].animal_count +
            this.pgie_summary_data[i].person_count +
            this.pgie_summary_data[i].vehicle_count
        );

        this.total_detected_objects = [
          {
            data: total_detected_objects,
            backgroundColor: "#42A5F5",
            borderColor: "#fff",
            label: "Detected Objects",
          },
        ];
      }
    },

  },
  computed: {
    chart_time_format() {
      let time_format = "MM-DD HH:mm";
      if (this.timeframe_tab == 0) {
        time_format = "MM-DD";
      }
      return time_format;
    },
    camera_models_pie() {
      let labels = [];
      let data = [];
      for (const i in this.cameras) {
        if (!labels.includes(this.cameras[i].model)) {
          labels.push(this.cameras[i].model);
          data.push(1);
        } else {
          data[labels.indexOf(this.cameras[i].model)]++;
        }
      }
      return { data: data, labels: labels };
    },
    blurriness_data() {
      let blurriness_data = { blurry: 0, not_blurry: 0, undefined: 0 };
      for (const i in this.cameras) {
        if (this.cameras[i].blurriness_label == "blurry") {
          blurriness_data.blurry++;
        } else if (this.cameras[i].blurriness_label == "not_blurry") {
          blurriness_data.not_blurry++;
        } else {
          blurriness_data.undefined++;
        }
      }

      return blurriness_data;
    },
    camera_data() {
      return this.$store.getters.getCameraDashboardAnalytics;
    },
    sorted_cameras() {
      let cameras = [...this.cameras];
      return cameras.sort((a, b) => {
        return (
          moment(b.latest_upload, "YYYY-MM-DDTHH:mm:ss") >
          moment(a.latest_upload, "YYYY-MM-DDTHH:mm:ss")
        );
      });
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    resolution_pie() {
      let labels = ["3840p", "2560p", "1920p", "1280p", "other"];
      let data = [0, 0, 0, 0, 0];
      for (const i in this.cameras) {
        if (this.cameras[i].latest_upload_resolution == "3840x2160") {
          data[0]++;
        } else if (this.cameras[i].latest_upload_resolution == "2560x1440") {
          data[1]++;
        } else if (this.cameras[i].latest_upload_resolution == "1920x1080") {
          data[2]++;
        } else if (this.cameras[i].latest_upload_resolution == "1280x720") {
          data[3]++;
        } else {
          data[4]++;
        }
      }
      return { data: data, labels: labels };
    },
    blur_pie() {
      let labels = ["blur", "not blur", "undefined"];
      let data = [0, 0, 0, 0, 0];
      for (const i in this.cameras) {
        if (this.cameras[i].blurriness_label == "blurry") {
          data[0]++;
        } else if (this.cameras[i].blurriness_label == "not_blurry") {
          data[1]++;
        } else {
          data[2]++;
        }
      }
      return { data: data, labels: labels };
    },
    pgie_summary_data() {
      return this.$store.getters.getSummaryPGIE;
    },
    timeframe() {
      if (this.timeframe_tab == 0) {
        return "1day";
      } else if (this.timeframe_tab == 1) {
        return "1hour";
      }
      if (this.timeframe_tab == 2) {
        return "5min";
      }
      return "1day";
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
    media_summary_data() {
      return this.$store.getters.getSummaryMedia;
    },

    detected_objects_per_media() {
      var objects_per_media = [];

      if (
        this.total_detected_objects[0].data.length &&
        this.inferred_media[0].data.length
      ) {
        for (const i in this.total_detected_objects[0].data) {
          objects_per_media[i] =
            this.total_detected_objects[0].data[i] /
            (this.inferred_media[0].data[i] + this.inferred_media[1].data[i]);
        }
      }
      return [
        {
          data: objects_per_media,
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "Objects",
        },
      ];
    },
  },
  mounted() {},

  created() {
    if (this.customer_id) {
      this.fetch_analytics();
      this.$store.dispatch("DDB_GET_CAMERA_DASHBOARD_ANALYTICS");
    }
  },
};
</script>
<style lang="css" scoped>
.dashboard_bot {
  padding: 3px;
  margin-top: 4px;
}

.dashboard_left {
  padding: 3px;
  padding-bottom: 0px;
  margin-bottom: -4px;
}
@media only screen and (min-width: 900px) {
  .dashboard_bot {
    padding: 3px;
    margin-top: 4px;
    height: 34vh;
    min-height: 350px;
  }

  .dashboard_left {
    padding: 3px;
    padding-bottom: 0px;
    margin-bottom: -4px;
    height: calc(86vh + 11px);
    min-height: 861px;
  }
}

@media only screen and (max-width: 899px) {
  .hidden_when_small {
    display: none !important;
  }
}
.dashboard_top {
  padding: 3px;
  height: 52vh;
  min-height: 500px;
  overflow-y: scroll;
}

#grid-container-lf {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}
.grid_container_f {
  position: relative;
  text-align: center;
}

.filter_conf {
  text-align: right;
}
#grid-container-lf {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}
.lu_tabs {
  float: none !important;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid_container_f:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px black;
  z-index: 2 !important;
}
.grid_container_f:hover > .cam_name_grid {
  z-index: 3 !important;
}

.bar_col > div {
  height: calc(34vh - 92px);
  position: relative;
  min-height: 272px;
}

.chart_smaller > div {
  height: calc(34vh - 78px);
  position: relative;
  min-height: 240px;
}

#general_users {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  top: 8%;
}
#vid_heading {
  position: absolute;
  font-size: 18px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#hw_deading {
  position: absolute;
  font-size: 18px;
  bottom: 16%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#num_users {
  font-size: 82px;
  position: absolute;
  top: 14%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#user_name {
  font-size: 28px;
  position: absolute;
  top: 14%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
}

.user_names {
  position: absolute;
  top: 30%;
  max-height: 200px;
  overflow-y: scroll;
}

#num_users_heading {
  font-size: 18px;
  position: absolute;

  left: 50%;
  transform: translate(-50%, -50%);
}
#card_body {
  text-align: center;
  /* height: 400px; */
}
#sub_stats {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 64px;
}
#sub_stats_vid {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  top: 44%;
}
.sub_stat_cols {
  position: relative;
}
.bordered {
  border-style: none solid none solid;
  border-width: 1px;
}

.bordered_right {
  border-style: none solid none none;
  border-width: 1px;
}
.sub_heading {
  font-size: 14px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -22%;
  min-width: 100px;
}
.sub_num {
  font-size: 32px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 32%;
}

.sub_heading2 {
  font-size: 12px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -12%;
}
.sub_num2 {
  font-size: 32px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 36%;
}
#loading_circle {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.num {
  font-size: 32px;
}

.heading {
  font-size: 14px;
}

.main_header {
  font-size: 18px;
}

.row_body {
  width: 100%;
}

.name_header {
  font-size: 28px;
}
</style>
  